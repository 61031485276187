@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');

@import '@fortawesome/fontawesome-free/css/all.css';

body {
  font-family: 'Noto Sans KR', 'Spoqa Hans Sans Neo', 'Roboto', sans-serif;
}
